<template>
  <module
    ref="module"
    id="servers"
    titleIcon="fa fa-server"
    api-url="ACCAPIURL_V4"
    :title="$t('servertxt')"
    :filters.sync="filters"
    :url="`/servers/search`"
    @on-save="submit()"
    use-default-crud
    hide-insert-button
    hide-edit-button
    hide-delete-button
  >
    <template slot="table-columns">
      <el-table-column :label="$t('action')" width="140px" align="center">
        <template slot-scope="{row}">
          <p-button
            size="sm"
            type="warning"
            class="py-1 px-2"
            round
            outline
            title="Force Discovery"
            @click="forceDiscovery(row)"
          >
            <i class="fa fa-slack"></i>
          </p-button>
          <p-button
            size="sm"
            type="primary"
            class="py-1 px-2 ml-1"
            round
            outline
            :title="$t('billinginstructions')"
            @click="edit(row, false)"
          >
            <i class="fa fa-pencil"></i>
          </p-button>
          <p-button
            size="sm"
            type="success"
            class="py-1 px-2 ml-1"
            round
            outline
            :title="$t('upentrytitle')"
            @click="edit(row, true)"
          >
            <i class="fa fa-wrench"></i>
          </p-button>
        </template>
      </el-table-column>
      <el-table-column prop="server" :label="$t('servertxt')" width="250px" sortable />
      <el-table-column prop="broker" :label="$t('broker')" width="220px" sortable />
      <el-table-column prop="platform" :label="$t('platformtxt')" width="90px" align="center" />
      <el-table-column prop="host" :label="$t('ip')" width="250px" />
      <el-table-column prop="symbols" :label="$t('ordersymbol')" show-overflow-tooltip >
        <template slot-scope="{row}">
          <small>{{ row.symbols }}</small>
        </template>
      </el-table-column>
      <el-table-column prop="multiplier" :label="$t('helper_lotmultiplier')" width="220px" sortable />
    </template>
    <template slot="filter-field">
      <fg-input v-model="filters.server" :label="$t('servertxt')"></fg-input>
      <fg-input v-model="filters.broker" :label="$t('broker')"></fg-input>
      <fg-input v-model="filters.multiplier" :label="$t('helper_lotmultiplier')"></fg-input>
    </template>
    <template slot="edt">
      <div v-if="tempSwitch===false">
        <div class="form-group>">
          <div class="grid-container">
            <fg-input
                v-model="newSymbol"
                replace="/^[a-zA-Z0-9]+$/;"
                type="text"
                class="input-short"
            />
            <p-button
                type="success"
                class="py-2 px-3"
                round
                outline
                :title="$t('addmarketentry')"
                @click="addSymbol"
                style="align-self: center; margin-right: 10px; width: max-content"
              >
                <i class="fa fa-plus"></i>
              </p-button>
            </div>
        </div>
        <el-table :data="serverSymbols">
          <el-table-column :label="$t('action')" width="100px" align="center">
            <template slot-scope="{row}">
              <p-button
                size="sm"
                type="danger"
                class="py-1 px-2"
                round
                outline
                title="Remove Symbol"
                @click="remSymbol(row.name)"
              >
                <i class="fa fa-remove"></i>
              </p-button>
            </template>
          </el-table-column>
          <el-table-column prop="name" :label="$t('ordersymbol')" />
        </el-table>
      </div>
      <div v-if="tempSwitch===true">
        <div class="form-group>">
          <div>
            <fg-input
                v-model="entries.broker"
                type="text"
                class="input-short"
                :label="this.$t('brokertxt')"
            />
            <fg-input
                v-model="entries.server"
                type="text"
                class="input-short"
                :label="this.$t('servertxt')"
            />
            <label> {{ this.$t('timezone') }} </label>
            <div/>
            <el-autocomplete
              class="input-short"
              v-model="entries.timezone"
              clearable
              :fetch-suggestions="querySearch"
              placeholder="Insert Timezone"
              :trigger-on-focus="false"
            />
            <fg-input
                v-model="entries.multiplier"
                type="number"
                class="input-short"
                :label="this.$t('helper_lotmultiplier')"
            />
            <fg-input
                v-model="entries.raw_offset"
                type="text"
                class="input-short"
                :label="this.$t('raw_offset')"
                disabled="disabled"
            />
          </div>
        </div>
      </div>
    </template>
  </module>
</template>

<script>
import Vue from 'vue'
import {Autocomplete} from 'element-ui'

Vue.use(Autocomplete)

export default {
  name: "ServerManagement",
  data() {
    return {
      filters: {
        server: '',
        broker: '',
        multiplier: '',
      },
      newSymbol: '',
      currentServer: '',
      serverSymbols: [],
      tempSwitch: false,
      entries: {},
      links: [],
    }
  },
  methods: {
    async forceDiscovery(row) {
      const response = await this.$forceServerDiscovery(row);
      if (this.$validateResponse(response)) {
        this.$refs.module.refreshList();
      }
    },
    edit(row,tswitch) {
      this.tempSwitch = tswitch
      this.clear();
      if (!this.tempSwitch) {
        this.currentServer = row.server;
        if (row.symbols && row.symbols.length) {
          this.serverSymbols =  row.symbols.split(',').map(s => ({name: s}));
        }
      } else {
        this.entries = row
      }
      this.$refs.module.doStep(2)
    },
    addSymbol() {
      if (this.newSymbol !== '') {
        this.serverSymbols.push({name: this.newSymbol});
        this.newSymbol = '';
      }
    },
    remSymbol(symbol) {
      this.serverSymbols = this.serverSymbols.filter(s => s.name !== symbol);
    },
    async submit() {
      if (!this.tempSwitch){
        const response = await this.$updateServerSymbols({
          server: this.currentServer,
          symbols: this.serverSymbols.map(s => s.name),
        });
        if (this.$validateResponse(response)) {
        this.clear();
        return true;
      }
      return false;
      } else {
        const response = await this.$upBrokerServers(this.entries)
        if (this.$validateResponse(response)) {
        this.clear();
        return true;
      }
      return false;
      }
    },
    clear() {
      this.serverSymbols = [];
      this.currentServer = '';
      this.newSymbol = '';
    },
    querySearch(queryString, cb) {
      const links = this.links;
      const results = queryString ? links.filter(this.createFilter(queryString)) : links;
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    loadThem(resp) {
      this.links = resp.data.timezones
    },
    loadAll() {
      this.$getAllTimezones().then(this.loadThem, this.failop);
    },
  },
  mounted() {
    this.loadAll();
  }
}
</script>

<style>
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  align-items: flex-end;
  justify-content: space-between;
}
.input-short {
  width: 100%;
  max-width: 1150px;
}
</style>
